.the-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: transparent;
}

.the-wrapper .icon {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  bottom: -12px;
  right: -10px;
}

.fa-file-invoice-dollar {
  text-shadow: 0 0 16px #ebe5e5;
}